import React from "react";

// Constants for app-specific details
const APP_NAME = "Swarna Shahi";
const DEVELOPER_NAME = "premjethwa";
const CONTACT_EMAIL = "swarnashahibysggold@gmail.com";
const BUSINESS_ADDRESS =
  "302, 3rd Floor, Madhuban, Muthaliya Residency, Dattaram Lad Marg, Mumbai 400033";
const LAST_UPDATED = "January 25, 2025"; //"[Month Day, Year]";
const OWNER_NAME = "Sujain Girish Jain (SG Gold)";

const PrivacyPolicy = () => {
  return (
    <div className="bg-background text-text p-8">
      <h1 className="text-3xl font-bold mb-4 text-primary">
        Privacy & Safety Policy
      </h1>

      <p className="text-sm mb-4">Owner: {APP_NAME}</p>
      <p className="text-sm mb-4">Developer: {DEVELOPER_NAME}</p>
      <p className="text-sm mb-4">Last Updated: {LAST_UPDATED}</p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        1. Introduction
      </h2>
      <p className="mb-4">
        Welcome to the <strong>{APP_NAME}</strong> (the “App”), developed and
        maintained by {DEVELOPER_NAME} (“we,” “our,” or “us”). We are committed
        to protecting the privacy and safety of our users. This Privacy & Safety
        Policy explains how we collect, use, share, and protect any information
        you provide when you use the App.
      </p>
      <p className="mb-4">
        By using the App, you agree to the terms of this Policy. If you do not
        agree, please discontinue use of the App.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        2. Information We Do Not Collect
      </h2>
      <ul className="list-disc pl-8 mb-4">
        <li>
          <strong>No Automatic Usage Data:</strong> We do not automatically
          collect IP addresses, browser types, device identifiers, or any usage
          patterns (e.g., pages viewed, time spent, or actions taken in the
          App).
        </li>
        <li>
          <strong>No Payment Data:</strong> The App does not facilitate online
          payments; all financial transactions and exchanges occur offline. We
          therefore do not collect or store payment card details or banking
          information.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        3. Information You Provide Voluntarily
      </h2>
      <p className="mb-4">
        While using the App, you may choose to provide certain personal
        information, such as:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>Name and Contact Details (e.g., phone number, email address)</li>
        <li>
          Delivery Address (if relevant to arranging a product/service delivery)
        </li>
      </ul>
      <p className="mb-4">
        Any personal information you provide is used strictly to fulfill your
        requests (e.g., placing an order, communicating order status) and to
        deliver customer support.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        4. How We Use Your Information
      </h2>
      <p className="mb-4">
        We may use the personal information you voluntarily provide to:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          Create or manage your user account (if the App has an account
          feature).
        </li>
        <li>Respond to inquiries or handle customer support.</li>
        <li>Fulfill orders or service requests you initiate.</li>
        <li>Send notifications (e.g., order updates or important notices).</li>
        <li>
          Comply with legal requirements and protect our rights or the rights of
          others.
        </li>
      </ul>
      <p className="mb-4">
        We will not use your information for any purpose not described in this
        Policy without your consent.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        5. Data Sharing and Disclosure
      </h2>
      <p className="mb-4">
        We will not share, sell, or rent your personal data to third parties,
        except:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          <strong>Service Providers:</strong> In limited cases, we may rely on
          service providers (e.g., for hosting) who assist in operating our App.
          They are obligated to keep your information confidential.
        </li>
        <li>
          <strong>Legal or Safety Reasons:</strong> We may disclose personal
          information where we believe it is necessary to comply with applicable
          laws, protect our rights or the safety of others, or respond to valid
          legal processes.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        6. Data Security
      </h2>
      <p className="mb-4">
        We implement reasonable technical and organizational measures to
        safeguard your personal information against unauthorized access,
        alteration, disclosure, or destruction. However, no method of
        transmission over the internet or electronic storage is completely
        secure; we cannot guarantee its absolute security.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        7. Data Retention and Deletion
      </h2>
      <ul className="list-disc pl-8 mb-4">
        <li>
          <strong>Retention:</strong> We keep your personal information only as
          long as necessary to fulfill the purposes outlined in this Policy
          (e.g., completing orders, providing support) or as required by law.
        </li>
        <li>
          <strong>Deletion:</strong> If you would like us to delete any personal
          information you have provided, please contact us (see “Contact Us”
          below), and we will make reasonable efforts to honor your request
          unless we are required by law or have a legitimate business need to
          retain it.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        8. Children’s Privacy
      </h2>
      <p className="mb-4">
        The App is not directed at children under 13. We do not knowingly
        collect personal information from anyone under 13. If you believe we
        have inadvertently collected such information, please contact us
        immediately so we can delete it.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        9. Changes to This Policy
      </h2>
      <p className="mb-4">
        We may update this Privacy & Safety Policy from time to time. Any
        changes will be effective when we post the revised Policy within the App
        or otherwise notify you. Your continued use of the App after changes are
        posted indicates your acceptance.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-primary-dark">
        10. Contact Us
      </h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy & Safety
        Policy, our data practices, or if you wish to exercise any of your
        rights, please contact us at:
      </p>
      <p className="mb-4">
        <strong>{OWNER_NAME}</strong>
        <br />
        Address: {BUSINESS_ADDRESS}
        <br />
        Email:{" "}
        <a href={`mailto:${CONTACT_EMAIL}`} className="text-primary">
          {CONTACT_EMAIL}
        </a>
      </p>

      <p className="mt-8 text-sm text-neutral-light">
        By using the App, you acknowledge that you have read and understand this
        Privacy & Safety Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
